import React from 'react';
import { gql } from 'apollo-boost';
import { Mutation } from 'react-apollo';
import { GET_STORES } from './GetStores';

const ADD_PRODUCT_IN_STORE = gql`
  mutation($_id: String!, $product: SubInputProduct!) {
    addProductInShop(_id: $_id, product: $product) {
      message
    }
  }
`;

const withAddProductInStore = Component => props => (
  <Mutation mutation={ADD_PRODUCT_IN_STORE}>
    {addProductInShop => (
      <Component
        addProductInShop={({ _id, product }) => {
          addProductInShop({
            variables: {
              _id,
              product,
            },
            refetchQueries: [{ query: GET_STORES }],
          });
        }}
        {...props}
      />
    )}
  </Mutation>
);

export default withAddProductInStore;
