import React from 'react';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';

export const GET_PAYMENTS = gql`
  query getAllPayments($page: Int!, $limit: Int!,$filterShop:String){
    getAllPayments(pagination:{page:$page,limit:$limit},filterShop:$filterShop) {
      data{
      _id
      shop{
        _id
        name
        code
        address
        city
        logo
        deleted
      }
      order{
        _id
        is_completed
        created_at
        totalPrice
        products{
          _id
          product{
            _id
            name
            image
            price
            currency
            description
            deleted
          }
          quantity
        }
      }
      status
      totalPrice
      totalAmount
      payerSwishId
      swishPaymentId
      swishPaymentReference
      paymentNumber
      paymentDate
      swishMessage
      klarnaOrderId
    }
  paginationData{
    totalDocs
  }
  }
  }
`;

const withPayments = Component => (props) => {
  const {
    page, limit, filterShop,
  } = props;
  console.log(limit);
  return (
    <Query
      query={GET_PAYMENTS}
      variables={{
        page, limit, filterShop,
      }}
    >
      {({ loading, data, error }) => {
        let errorTosend;
        if (error) {
          errorTosend = error.networkError.result;
        }
        return (
          <Component
            loading={loading}
            apolloError={errorTosend}
            payments={data && data.getAllPayments}
            {...props}
          />
        );
      }}
    </Query>
  );
}

export default withPayments;
