import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'
import { GET_ANALYSES } from './GetAnalyses'
import { GET_ANALYSES_GROUP } from './GetAnalysesGroup'

const DELETE_ANALYSIS = gql`
  mutation($_id: String!,$_db:String,$bool:Boolean) {
    deleteAnalysis(_id: $_id,_db:$_db,bool:$bool) {
      message
    }
  }
`

const withDeleteAnalysis = Component => props => (
  <Mutation mutation={DELETE_ANALYSIS}>
    {deleteAnalysis => (
      <Component
        deleteAnalysis={({ _id, _db, bool }) => deleteAnalysis({
          variables: { _id, _db, bool },
          refetchQueries: [
            { query: GET_ANALYSES, variables: { _db: props._db } },
            {
              query: GET_ANALYSES_GROUP, variables: { _db: props._db },
            },
          ],
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
)

export default withDeleteAnalysis
