import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'

const UPLOAD_IMAGE = gql`
  mutation UploadFile($file: Upload!) {
    uploadFile(file: $file)
  }
`

const withUploadImage = Component => props => (
  <Mutation mutation={UPLOAD_IMAGE}>
    {uploadFile => (
      <Component
        uploadFile={file => uploadFile({ variables: { file } })}
        {...props}
      />
    )}
  </Mutation>
)

export default withUploadImage
