import React from 'react';
import { gql } from 'apollo-boost';
import { Mutation } from 'react-apollo';
import { GET_STORES } from './GetStores';

const ADD_CATEGORY_IN_STORE = gql`
  mutation($_id: String!, $category: String!) {
    addCategoryInShop(_id: $_id, category: $category) {
      message
    }
  }
`;

const withAddCategoryInStore = Component => props => (
  <Mutation mutation={ADD_CATEGORY_IN_STORE}>
    {addCategoryInShop => (
      <Component
        addCategoryInShop={({ _id, category }) => {
          addCategoryInShop({
            variables: {
              _id,
              category,
            },
            refetchQueries: [{ query: GET_STORES }],
          });
        }}
        {...props}
      />
    )}
  </Mutation>
);

export default withAddCategoryInStore;
