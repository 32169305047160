import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'

const EDIT_MY_SETTINGS = gql`
  mutation($settings: UserSettingInput!) {
    editMySetting(settings: $settings) {
      id
      message
    }
  }
`

const withEditMySettings = Component => props => (
  <Mutation mutation={EDIT_MY_SETTINGS}>
    {editMySetting => (
      <Component
        editMySetting={({ settings }) => editMySetting({
          variables: { settings },
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
)


export default withEditMySettings
