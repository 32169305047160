import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'
import { GET_USERS } from './GetUsers'

const EDIT_USER = gql`
  mutation(
    $_id: String!
    $firstName: String
    $lastName: String
    $title: String
    $phone1: String
    $phone2: String
    $email: String!
    $role: String!
  ) {
    editUser(
      _id: $_id
      firstName: $firstName
      lastName: $lastName
      title:$title
      email: $email
      role: $role
      phone1:$phone1
      phone2:$phone2
    ) {
      title
      lastName
      firstName
      email
      _id
      role
      phone1
      phone2
    }
  }
`

const withEditUser = Component => props => (
  <Mutation mutation={EDIT_USER}>
    {editUser => (
      <Component
        editUser={({
          firstName,
          lastName,
          email,
          role,
          _id,
          phone1,
          phone2,
          title,
        }) => editUser({
          variables: {
            firstName,
            lastName,
            email,
            role,
            _id,
            title,
            phone1,
            phone2,
          },
          refetchQueries: [{ query: GET_USERS }],
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
)

export default withEditUser
