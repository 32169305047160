import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'
import { GET_ANALYSES } from './GetAnalyses'
import { GET_ANALYSES_GROUP } from './GetAnalysesGroup'

const ADD_ANALYSIS = gql`
  mutation($name: String!,$blocks:[blockInput!],$_db:String) {
    addAnalysis(name: $name,blocks:$blocks,_db:$_db) {
      name
    }
  }
`

const withAddAnalysis = Component => props => (
  <Mutation mutation={ADD_ANALYSIS}>
    {addAnalysis => (
      <Component
        addAnalysis={({
          name, blocks, _db,
        }) => addAnalysis({
          variables: {
            name, blocks, _db,
          },
          refetchQueries: [
            { query: GET_ANALYSES, variables: { _db: props._db } },
            {
              query: GET_ANALYSES_GROUP, variables: { _db: props._db },
            },
          ],
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
);

export default withAddAnalysis
