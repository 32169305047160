import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'

const REMOVE_ACCOUNT_USER = gql`
  mutation(
    $userId:String!
    $accountId:String!
  ) {
    removeAccountUser(
      userId: $userId
      accountId: $accountId
    ) {
      message
    }
  }
`

const withRemoveAccountUser = Component => props => (
  <Mutation mutation={REMOVE_ACCOUNT_USER}>
    {removeAccountUser => (
      <Component
        removeAccountUser={({ userId, accountId }) => removeAccountUser({
          variables: { userId, accountId },
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
)

export default withRemoveAccountUser
