import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'

const CHANGE_MY_PASSWORD = gql`
  mutation(
    $oldPassword:String!
    $newPassword:String!
  ) {
    changeMyPassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      _id
      message
    }
  }
`

const withChangeMyPassword = Component => props => (
  <Mutation mutation={CHANGE_MY_PASSWORD}>
    {changeMyPassword => (
      <Component
        changeMyPassword={({ oldPassword, newPassword }) => changeMyPassword({
          variables: { oldPassword, newPassword },
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
)

export default withChangeMyPassword
