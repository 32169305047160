import React from 'react';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';

export const GET_PAGINATED_AUTHENTICATIONS = gql`
  query getPaginatedAuthentications(
    $page: Int!
    $limit: Int!
    $filterShop: String
    $completed: Boolean
  ) {
    getPaginatedAuthentications(
      pagination: { page: $page, limit: $limit }
      filterShop: $filterShop
      completed: $completed
    ) {
      data {
        _id
        shop {
          _id
          name
          code
        }
        clientIp
        orderTime
        authSuccess
# inside modal
        orderRef
        authSuccessTime
        authenticatedUser {
          name
          personalNumber
          ipAddress
        }
      }
      paginationData {
        totalDocs
      }
    }
  }
`;

const withPaginatedAuthentications = Component => (props) => {
  const {
    page, limit, filterShop, completed,
  } = props;
  console.log(limit);
  return (
    <Query
      query={GET_PAGINATED_AUTHENTICATIONS}
      variables={{
        page, limit, filterShop, completed,
      }}
    >
      {({ loading, data, error }) => {
        let errorToSend;
        if (error) {
          errorToSend = error.networkError.result;
        }

        return (
          <Component
            loading={loading}
            apolloError={errorToSend}
            authentications={data && data.getPaginatedAuthentications}
            {...props}
          />
        );
      }}
    </Query>
  );
}

export default withPaginatedAuthentications;
