import React from 'react'
import { gql } from 'apollo-boost'
import { Query } from 'react-apollo'

export const GET_SPECIFIC_STORES = gql`
  query(
    $partner: Boolean
    ){
    getSpecificShops(
      partner: $partner
      ){
      _id
      name
    }
  }
`

const withStores = Component => (props) => {
  const { isPartner } = props;
  return (
    <Query query={GET_SPECIFIC_STORES} variables={{ partner: isPartner }}>
      {({ loading, data, error }) => {
        let errorTosend
        if (error) {
          errorTosend = error.networkError.result
        }
        return (
          <Component
            loading={loading}
            apolloError={errorTosend}
            items={data && data.getSpecificShops}
            list={data && (data.getSpecificShops || [])}
            {...props}
          />
        )
      }}
    </Query>
  )
}

export default withStores
