import {
  ApolloLink, ApolloClient, concat, InMemoryCache,
} from 'apollo-boost'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from 'apollo-link-error';
import { notification } from 'antd';

// export const serverLink = 'https://pure-shelf-30254.herokuapp.com/graphql'
// eslint-disable-next-line import/no-mutable-exports
let serverLink = 'https://www.closebuy.se/api'

if (window.location.hostname.includes('stage')) {
  serverLink = 'https://closebuy-stage.digitalplattform.se/api'
}
if (window.location.hostname.includes('local')) {
  serverLink = 'http://localhost:4001/api'
}


const httpLink = createUploadLink({ uri: serverLink })

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
}

const onErrorGraphql = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      notification.error({ message })
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      return null
    });
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const userObj = JSON.parse(global.localStorage.getItem('userInfo'))
  const dbName = global.sessionStorage.getItem('datbaseName')
  console.log('Datbase Here Client ===>>', dbName)
  operation.setContext({
    headers: {
      'x-token': userObj.token || '',
    },
  })

  return forward(operation)
})

const client = new ApolloClient({
  link: concat(authMiddleware, onErrorGraphql.concat(httpLink)),
  cache: new InMemoryCache(),
  defaultOptions,
})

export { serverLink }
export default client
