import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'
import { GET_CURRENT_USERS } from './CurrentUser';

const UPDATE_SELECTED_ACCOUNT = gql`
  mutation($selectedAccount: String!) {
    updateSelectedAccount(selectedAccount: $selectedAccount) {
      message
    }
  }
`

const withUpdateSelectedAccount = Component => props => (
  <Mutation mutation={UPDATE_SELECTED_ACCOUNT}>
    {updateSelectedAccount => (
      <Component
        updateSelectedAccount={({ selectedAccount }) => updateSelectedAccount({
          variables: { selectedAccount },
          refetchQueries: [{ query: GET_CURRENT_USERS }],
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
)


export default withUpdateSelectedAccount
