import React, { Component } from 'react';
import IdleTimer from 'react-idle-timer';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import BroadcastChannel from 'broadcast-channel'
import { withLogoutUser } from '../../hocs'

@withLogoutUser
@withRouter
@connect()
class IdleTime extends Component {
  constructor(props) {
    super(props)
    this.idleTimer = null
    this.buttonClick = this.buttonClick.bind(this)
    // this.onAction = this.onAction.bind(this)
    // this.onActive = this.onActive.bind(this)
    this.onIdle = this.onIdle.bind(this)
    // eslint-disable-next-line no-undef
    this.channel = new BroadcastChannel('testChannel')
  }


  componentDidMount() {
    console.log('Logs from timer', this.props);

    this.channel.onmessage = (data) => {
      this.idleTimer.reset()
      console.log(data)
    }
  }

  /**
  onActive(e) {
    this.channel.postMessage('test me hererer react')
    console.log('user is active', e)
    console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  onAction(e) {
    console.log('Action happens here');
    const { idleTimer } = this
    this.channel.postMessage('test me hererer react')
    console.log('user did something', e, idleTimer)
  }
  */

  onIdle() {
    const { dispatch, logout } = this.props
    const userObj = global.localStorage.getItem('userInfo');
    if (userObj) {
      logout()
      dispatch({
        type: 'user/LOGOUT',
      })
    } else {
      window.location.reload()
    }
  }

  buttonClick() {
    console.log('click work');
    this.channel.postMessage('test me hererer')
  }

  render() {
    return (
      <IdleTimer
        ref={(ref) => { this.idleTimer = ref }}
        element={global.document}
        // onAction={this.onAction}
        // onActive={this.onAction}
        onIdle={this.onIdle}
        debounce={250}
        timeout={1000 * 60 * 60 * 2} // Two hours = 7200000 milliseconds
      />
    );
  }
}

export default IdleTime;
