import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'
import { GET_PRODUCTS } from './GetProducts'
import { GET_STORES } from '../store/GetStores'

const EDIT_PRODUCT = gql`
  mutation(
    $_id: String!
    $name: String!
    $image: String
    $price: Float!
    $currency: String!
    $description: String!
    $productNumber: String!
    $shopId: String
    $taxRate: Float
  ) {
    editProduct(
      _id: $_id
      name: $name
      image: $image
      price: $price
      currency: $currency
      description: $description
      productNumber: $productNumber
      shopId: $shopId
      taxRate: $taxRate
    ) {
        message,
        success
    }
  }
`

const withEditProduct = Component => props => (
  <Mutation mutation={EDIT_PRODUCT}>
    {editProduct => (
      <Component
        editProduct={({
          _id,
          name,
          image,
          price,
          currency,
          description,
          productNumber,
          shopId,
          taxRate,
        }) => editProduct({
          variables: {
            _id,
            name,
            image,
            price,
            currency,
            description,
            productNumber,
            shopId,
            taxRate,
          },
          // refetchQueries: [{ query: GET_PRODUCTS }],
          refetchQueries: [...(shopId ? (
            [{ query: GET_STORES }, { query: GET_PRODUCTS }]
          ) : (
            [{ query: GET_PRODUCTS }]
          ))],
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
)

export default withEditProduct
