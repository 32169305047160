import antdData from 'antd/lib/locale-provider/default'
import localeData from 'react-intl/locale-data/sw';
import messages from './translations/sw-SW.json'


export default {
  locale: 'sw-SW',
  antdData,
  localeData,
  messages,
}
