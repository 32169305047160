import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'
import { GET_CURRENT_USERS } from './CurrentUser'

const EDIT_CURRENT_USER = gql`
  mutation(
    $firstName: String
    $lastName: String
    $title: String
    $phone1: String
    $phone2: String
    $email: String!
  ) {
    editCurrentUser(
      firstName: $firstName
      lastName: $lastName
      title:$title
      email: $email
      phone1:$phone1
      phone2:$phone2
    ) {
      title
      lastName
      firstName
      email
      _id
      phone1
      phone2
    }
  }
`

const withEditCurrentUser = Component => props => (
  <Mutation mutation={EDIT_CURRENT_USER}>
    {editCurrentUser => (
      <Component
        editCurrentUser={({
          firstName,
          lastName,
          email,
          phone1,
          phone2,
          title,
        }) => editCurrentUser({
          variables: {
            firstName,
            lastName,
            email,
            title,
            phone1,
            phone2,
          },
          refetchQueries: [{ query: GET_CURRENT_USERS }],
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
)

export default withEditCurrentUser
