import React from 'react'
import { ConfigProvider } from 'antd'
import { IntlProvider, addLocaleData } from 'react-intl'
import { connect } from 'react-redux'
import english from '../../../locales/en-US'
import french from '../../../locales/fr-FR'
import russian from '../../../locales/ru-RU'
import chinese from '../../../locales/zh-CN'
import swedish from '../../../locales/sw-SW'

addLocaleData(english.localeData)
addLocaleData(french.localeData)
addLocaleData(russian.localeData)
addLocaleData(chinese.localeData)
addLocaleData(swedish.localeData)

console.log('English', english)
const locales = {
  'en-US': english,
  'fr-FR': french,
  'ru-RU': russian,
  'zh-CN': chinese,
  'sw-SW': swedish,
}

@connect(({ settings }) => ({ settings }))
class Localization extends React.Component {
  render() {
    const {
      children,
      settings: { locale },
    } = this.props
    const currentLocale = locales[locale]
    console.log('Current Locale', currentLocale)
    console.log('locale', locale)
    console.log('locales', locales)
    return (
      <ConfigProvider locale={currentLocale.antdData}>
        <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
          {children}
        </IntlProvider>
      </ConfigProvider>
    )
  }
}

export default Localization
