import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'
import { GET_ANALYSES_GROUP } from './GetAnalysesGroup'

const ADD_ANALYSIS_GROUP = gql`
  mutation($name: String!,$_db:String) {
    addAnalysisGroup(name: $name,_db:$_db) {
      name
    }
  }
`

const withAddAnalysisGroup = Component => props => (
  <Mutation mutation={ADD_ANALYSIS_GROUP}>
    {addAnalysisGroup => (
      <Component
        addAnalysisGroup={({
          name, _db,
        }) => addAnalysisGroup({
          variables: {
            name, _db,
          },
          refetchQueries: [{ query: GET_ANALYSES_GROUP, variables: { _db: props._db } }],
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
);

export default withAddAnalysisGroup
