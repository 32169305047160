import React from 'react'
import { gql } from 'apollo-boost'
import { Query } from 'react-apollo'

export const GET_CURRENT_USERS = gql`
  {
    currentUser {
      _id
      title
      firstName
      lastName
      email
      phone1
      phone2
      role
      profileImage
    }
  }
`

const withCurrentUser = Component => props => (
  <Query query={GET_CURRENT_USERS}>
    {({ loading, data, error }) => {
      let errorTosend
      if (error) {
        errorTosend = error.networkError.result
      }
      return (
        <Component
          loading={loading}
          apolloError={errorTosend}
          currentUser={data && data.currentUser}
          {...props}
        />
      )
    }}
  </Query>
)

export default withCurrentUser
