import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'
import { GET_STORES } from './GetStores'

const DELETE_PRODUCT_IN_STORE = gql`
  mutation(
    $_id: String!
    $productId: String!
  ) {
    deleteProductInShop(
      _id: $_id
      productId: $productId
    ) {
        message,
        success
    }
  }
`

const withDeleteProductInStore = Component => props => (
  <Mutation mutation={DELETE_PRODUCT_IN_STORE}>
    {deleteProductInShop => (
      <Component
        deleteProductInShop={({
          _id,
          productId,
        }) => deleteProductInShop({
          variables: {
            _id,
            productId,
          },
          refetchQueries: [{ query: GET_STORES }],
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
)

export default withDeleteProductInStore
