import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'

const LOGOUT_USER = gql`
  mutation{
    logout{
      message
    }
  }
`

const withLogoutUser = Component => props => (
  <Mutation mutation={LOGOUT_USER}>
    {logout => (
      <Component
        logout={() => logout()
        }
        {...props}
      />
    )
    }
  </Mutation>
)

export default withLogoutUser
