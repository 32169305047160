import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'
import { GET_STORES } from './GetStores'

const DELETE_STORE = gql`
  mutation(
    $_id: String!
  ) {
    deleteShop(
      _id: $_id
    ) {
        message,
        success
    }
  }
`

const withDeleteStore = Component => props => (
  <Mutation mutation={DELETE_STORE}>
    {deleteShop => (
      <Component
        deleteShop={({
          _id,
        }) => deleteShop({
          variables: {
            _id,
          },
          refetchQueries: [{ query: GET_STORES }],
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
)

export default withDeleteStore
