import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'
import { GET_STORES } from './GetStores'

const EDIT_STORE = gql`
  mutation(
    $_id: String!
    $name: String50!
    $address: String50!
    $city: String!
    $logo: String!
    $postalCode: Int!
    $dealPercentage: Int
    $bankAccNum: String
  ) {
    editShop(
      _id: $_id
      name: $name
      address: $address
      city: $city,
      logo: $logo
      postalCode: $postalCode
      dealPercentage: $dealPercentage
      bankAccNum: $bankAccNum
    ) {
        message,
        success
    }
  }
`

const withEditStore = Component => props => (
  <Mutation mutation={EDIT_STORE}>
    {editShop => (
      <Component
        editShop={({
          _id,
          name,
          code,
          address,
          city,
          logo,
          postalCode,
          dealPercentage,
          bankAccNum,
        }) => editShop({
          variables: {
            _id,
            name,
            code,
            address,
            city,
            logo,
            postalCode,
            dealPercentage,
            bankAccNum,
          },
          refetchQueries: [{ query: GET_STORES }],
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
)

export default withEditStore
