import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'
import { GET_ANALYSES_GROUP } from './GetAnalysesGroup'
import { GET_ANALYSES } from './GetAnalyses'

const DELETE_ANALYSIS_GROUP = gql`
  mutation($_id: String!,$_db:String,$bool:Boolean) {
    deleteAnalysisGroup(_id: $_id,_db:$_db,bool:$bool) {
      message
      _id
    }
  }
`

const withDeleteAnalysisGroup = Component => props => (
  <Mutation mutation={DELETE_ANALYSIS_GROUP}>
    {deleteAnalysisGroup => (
      <Component
        deleteAnalysisGroup={({
          _id, _db, bool,
        }) => deleteAnalysisGroup({
          variables: {
            _id, _db, bool,
          },
          refetchQueries: [
            {
              query: GET_ANALYSES, variables: { _db: props._db },
            },
            {
              query: GET_ANALYSES_GROUP, variables: { _db: props._db },
            },
          ],
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
);

export default withDeleteAnalysisGroup
