import React, { Fragment } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import Loader from '../components/Layout/Loader'
import PublicLayout from './Public'
import LoginLayout from './Login'
import MainLayout from './Main'

const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
}

@withRouter
@connect(({ user }) => ({ user }))
class IndexLayout extends React.PureComponent {
  previousPath = ''

  componentDidUpdate(prevProps) {
    const { location } = this.props
    const { prevLocation } = prevProps
    if (location !== prevLocation) {
      global.window.scrollTo(0, 0)
    }
  }

  render() {
    const {
      children,
      location: { pathname, search },
      user,
    } = this.props

    // NProgress Management
    const currentPath = pathname + search
    if (currentPath !== this.previousPath) {
      NProgress.start()
    }

    setTimeout(() => {
      NProgress.done()
      this.previousPath = currentPath
    }, 300)

    // Layout Rendering
    const getLayout = () => {
      console.log(pathname, 'pathnamepathnamepathname')
      if (pathname === '/') {
        return 'public'
      }
      if (/^\/user(?=\/|$)/i.test(pathname) || pathname.slice(0, 3) === '/p/') {
        return 'login'
      }
      return 'main'
    }

    const Container = Layouts[getLayout()]
    const isUserAuthorized = user.authorized
    const isUserLoading = user.loading
    const isLoginLayout = getLayout() === 'login'

    const BootstrappedLayout = () => {
      // show loader when user in check authorization process
      // not authorized yet and not on login pages
      if (isUserLoading && !isUserAuthorized && !isLoginLayout) {
        return <Loader />
      }

      if (pathname.slice(0, 3) === '/p/') {
        console.log(window.location.pathname, 'public')
      } else {
        if (!isLoginLayout && !isUserAuthorized) {
          return <Redirect to="/user/login" />
        }
        // redirect to main planing view when user on login page and authorized
        if (isLoginLayout && isUserAuthorized) {
          return <Redirect to="/store" />
        }
      }
      // redirect to login page if current is not login page and user not authorized
      // in other case render previously set layout
      return <Container>{children}</Container>
    }

    return (
      <Fragment>
        <Helmet titleTemplate="Closebuy" title="React Admin Template" />
        {BootstrappedLayout()}
      </Fragment>
    )
  }
}

export default IndexLayout
