// Analysis
export { default as withAnalyses } from './analysis/GetAnalyses'
export { default as withAddAnalysis } from './analysis/AddAnalysis'
export { default as withDeleteAnalysis } from './analysis/DeleteAnalysis'
export { default as withUpdateAnalysis } from './analysis/UpdateAnalysis'
export { default as withAnalysesGroup } from './analysis/GetAnalysesGroup'
export { default as withAddAnalysisGroup } from './analysis/AddAnalysisGroup'
export { default as withUpdateAnalysisGroup } from './analysis/UpdateAnalysisGroup'
export { default as withDeleteAnalysisGroup } from './analysis/deleteAnalysisGroup'
export { default as withDeleteAnalysisGroupWithAnalysis } from './analysis/deleteAnalysisGroupWithAnalysis'

// Users
export { default as withAddUser } from './users/AddUser'
export { default as withUsers } from './users/GetUsers'
export { default as withCurrentUser } from './users/CurrentUser'
export { default as withEditUser } from './users/EditUser'
export { default as withAddAccountUser } from './users/AddAccountInUser'
export { default as withRemoveAccountUser } from './users/RemoveAccountInUser'
export { default as withEditCurrentUser } from './users/EditCurrentUser'
export { default as withEditMySettings } from './users/EditMySetting'
export { default as withChangeCrrentUserImage } from './users/ChangeCurrentUserProfilePicture'
export { default as withChangeMyPassword } from './users/ChangeMyPassword'
export { default as withSingleAccountUsers } from './users/GetSingleAccountUsers'
export { default as withUpdateDashboardSetting } from './users/UpdateDashboardSetting'
export { default as withUpdateDashboardResponsible } from './users/UpdateDashboardResponsible'
export { default as withUpdateSelectedAccount } from './users/UpdateSelectedAccount'
export { default as withLogoutUser } from './users/LogoutUser'

// Store
export { default as withAddStore } from './store/AddStore'
export { default as withStores } from './store/GetStores'
export { default as withSpecificStores } from './store/GetSpecificStores'
export { default as withSaveFrontPage } from './store/SaveFrontPage'

export { default as withEditStore } from './store/EditStore'
export { default as withDeleteStore } from './store/DeleteStore'
export { default as withAddProductInStore } from './store/AddProductInStore'
export { default as withAddCategoryInStore } from './store/AddCategoryInShop'
export { default as withEditProductInStore } from './store/EditProductInStore'
export { default as withDeleteProductInStore } from './store/DeleteProductInStore'
export { default as withDeleteCategoryInStore } from './store/DeleteCategoryInStore'
export { default as withRcoDoors } from './store/GetRcoDoors'
export { default as withEditDoorInStore } from './store/EditDoorInStore'
export { default as withDeleteDoorInStore } from './store/DeleteDoorInShop'

// Product
export { default as withAddProduct } from './product/AddProduct'
export { default as withProducts } from './product/GetProducts'
export { default as withEditProduct } from './product/EditProduct'
export { default as withDeleteProduct } from './product/DeleteProduct'

// Categories
export { default as withAddCategory } from './categories/AddCategory'
export { default as withCategories } from './categories/GetCategories'
export { default as withEditCategory } from './categories/EditCategory'
export { default as withDeleteCategory } from './categories/DeleteCategory'

// Orders
export { default as withOrders } from './orders/GetOrders'

// Payments
export { default as withPayments } from './payments/GetPayments'
export { default as withSelectedPayments } from './payments/GetSelectedPayments'
export { getSelectedPaymentsWithPromise } from './payments/GetSelectedPayments'

// HelperRoutes
export { default as withUploadImage } from './HelperServices/UploadImage'

// Authentications
export { default as withPaginatedAuthentications } from './authentications/GetPaginatedAuthentications'
