import React from 'react'
import { DraggableModal } from 'ant-design-draggable-modal'
import { Modal, Spin } from 'antd'
// import 'antd/dist/antd.css'
import styles from './style.module.scss';


class DPModal extends React.Component {
  // state = { autoHeight: false }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.visible) {
  //     console.log('nextProps.visiblenextProps.visiblenextProps.visiblenextProps.visible');
  //     // eslint-disable-next-line no-undef
  //     const antModal = document.getElementsByClassName('ant-modal');
  //     console.log(antModal);
  //     if (antModal[0]) {
  //       antModal[0].style.height = 'auto'
  //     }
  //   }
  // }

  render() {
    const {
      wrapClassName,
      children,
      resizable = true,
      width,
      height,
      draggable = true,
      loading,
      mask = true,
      modalCustomStyle = false,
    } = this.props;
    // const { autoHeight } = this.state;
    const newProps = {};

    if (modalCustomStyle) {
      newProps.style = { height: '582px', top: '100px' }
    }

    return (
      <DraggableModal
        {...this.props}
        wrapClassName={`draggable ${!draggable && 'fixed'} ${!resizable && 'non-resizeable'} ${!resizable && !width && 'non-resizeable-width'} ${!resizable && !height && 'non-resizeable-height'} ${!mask && 'non-mask'} ${wrapClassName}`}
        maskClosable
        {...newProps}
        // className="abc"
        // style={autoHeight ? {} : {}}
        // style={bd && { height: '10px' }}
      >
        <>
          {loading && <div className={styles.loading}><Spin /></div>}
          <span className={loading ? styles.hide : ''}>{children}</span>
        </>
        <Modal />
      </DraggableModal>
    )
  }
}

export default DPModal;
