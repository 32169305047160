import React from 'react';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import client from '../../config/createApolloClient';

export const GET_SELECTED_PAYMENTS = gql`
  query getSelectedPayments($startDate:String, $endDate:String,$filterShop:String){
    getSelectedPayments(startDate:$startDate, endDate:$endDate,filterShop:$filterShop) {
      _id
      shop{
        _id
        name
        code
        address
        city
        logo
        deleted
      }
      order{
        _id
        is_completed
        created_at
        totalPrice
        products{
          _id
          product{
            _id
            name
            image
            price
            currency
            description
            deleted
          }
          quantity
        }
      }
      status
      totalPrice
      totalAmount
      payerSwishId
      swishPaymentId
      swishPaymentReference
      paymentNumber
      paymentDate
      swishMessage
      klarnaOrderId
  }
  }
`;

const withSelectedPayments = Component => (props) => {
  const {
    startDate, endDate, filterShop,
  } = props;
  return (
    <Query
      query={GET_SELECTED_PAYMENTS}
      variables={{
        startDate, endDate, filterShop,
      }}
    >
      {({ loading, data, error }) => {
        let errorTosend;
        if (error) {
          errorTosend = error.networkError.result;
        }
        return (
          <Component
            loading={loading}
            apolloError={errorTosend}
            payments={data && data.getSelectedPayments}
            {...props}
          />
        );
      }}
    </Query>
  );
}

export const getSelectedPaymentsWithPromise = ({ startDate, endDate, filterShop }) => new Promise(
  async (resolve, reject) => {
    try {
      const response = await client.query({
        query: GET_SELECTED_PAYMENTS,
        variables: { startDate, endDate, filterShop },
        fetchPolicy: 'network-only',
      })
      if (response.errors?.length) {
        reject(response.errors.map(e => e.message).join(','))
      }
      resolve(response.data?.getSelectedPayments)
    } catch (error) {
      reject(error);
    }
  },
)


export default withSelectedPayments;
