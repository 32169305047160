import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'

import Loader from './components/Layout/Loader'
import IndexLayout from './layouts'
import NotFoundPage from './views/404'
import IdleTime from './components/IdleTimer'

const loadable = loader => Loadable({
  loader,
  delay: false,
  loading: () => <Loader />,
})

const routes = [
  // User login
  {
    path: '/user/login',
    component: loadable(() => import('views/LoginView')),
    exact: true,
  },
  // User Forget Password View
  {
    path: '/user/forgot',
    component: loadable(() => import('views/ForgotView')),
    exact: true,
    hidden: false,
  },
  // User Reset Password View
  {
    path: '/user/password/reset',
    component: loadable(() => import('views/PasswordResetView')),
    exact: true,
    hidden: false,
  },
  // Dashboard View
  {
    path: '/dashboard',
    component: loadable(() => import('views/DashboardView')),
  },
  // Analysis View
  {
    path: '/analysis',
    component: loadable(() => import('views/AnalysisView')),
  },
  {
    path: '/store',
    component: loadable(() => import('views/StoreView')),
  },
  {
    path: '/users',
    component: loadable(() => import('views/UserManagmentView')),
    exact: true,
  },
  {
    path: '/product',
    component: loadable(() => import('views/ProductView')),
    exact: true,
  },
  {
    path: '/categories',
    component: loadable(() => import('views/CategoriesView')),
    exact: true,
  },
  // {
  //   path: '/orders',
  //   component: loadable(() => import('views/OrderView')),
  //   exact: true,
  // },
  {
    path: '/payments',
    component: loadable(() => import('views/PaymentView')),
    exact: true,
  },
  {
    path: '/authentications',
    component: loadable(() => import('views/AuthenticationView')),
    exact: true,
  },
]

class Router extends React.Component {
  render() {
    const { history } = this.props
    console.log('History ==>', history)
    console.log('route ==>', routes)
    return (
      <ConnectedRouter history={history}>
        <IdleTime />
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/user/login" />} />
            {routes.map(route => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default Router
