import axios from 'axios'
import { serverLink } from '../config/createApolloClient'


export async function currentAccount() {
  const userObj = JSON.parse(global.localStorage.getItem('userInfo'))
  console.log('This is object ==>', userObj)
  if (userObj) {
    return axios
      .post(
        serverLink,
        {
          query: `query callLog{afterTokenCheck{
            token
            _id
            title
            lastName
            firstName
            email
            role
            phone1
            phone2
          }}`,
        },
        { headers: { 'x-token': userObj.token } },
      )
      .then((response) => {
        let userObjFromServer
        if (response.data.data) {
          userObjFromServer = response.data.data.afterTokenCheck
          global.localStorage.setItem('userInfo', JSON.stringify(userObjFromServer))
          return userObjFromServer
        }
        global.localStorage.removeItem('userInfo')
        return null
        // console.log("This is reponse server  ===>", response.data.data.afterTokenCheck)
      })
      .catch((err) => {
        global.localStorage.removeItem('userInfo')
        console.log('This is Error  ===>', err)
        return null
      })
  }
  return null
}

export async function logout() {
  global.localStorage.removeItem('userInfo')
  return true
}
