import React, { Component } from 'react';
import { Form, Input, Button } from 'antd';
import { FormattedMessage } from 'react-intl'

@Form.create()
class ChangePasswordForm extends Component {
  state = {
    confirmDirty: false,
    // editSettings: false,
    changePassword: false,
    // setImage: null,
    // autoCompleteResult: [],
    // firstName: '',
    // lastName: '',
    // title: '',
    // phone1: '',
    // phone2: '',
    // email: '',
    // profileImage: '',
  };

  handleConfirmBlur = (e) => {
    const { confirmDirty } = this.state;
    const { value } = e.target;

    this.setState({ confirmDirty: confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('newPass')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { confirmDirty } = this.state;
    const { form } = this.props;

    if (value && confirmDirty) {
      form.validateFields(['confirmPass'], { force: true });
    }
    callback();
  };

  onSubmit = () => {
    const { form } = this.props;
    form.validateFields((error, values) => {
      console.log(values);
      // form.resetFields()
    });
  };

  updatePassword = () => {
    const { form, onUpdatePassword } = this.props;
    form.validateFields((error, values) => {
      if (values.oldPass && values.newPass === values.confirmPass) {
        const saveData = {
          oldPassword: values.oldPass,
          newPassword: values.newPass,
        };
        // console.log(saveData)
        onUpdatePassword(saveData);
      }
    });
  };

  render() {
    const { changePassword } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 6,
        },
      },
    };
    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>
        <Form.Item {...tailFormItemLayout}>
          {!changePassword && (
            <Button
              // type="primary"
              onClick={() => this.setState({ changePassword: true })}
            >
              <FormattedMessage id="mySetting.changePassword.button.text" />
            </Button>
          )}
        </Form.Item>
        {changePassword && (
          <Form.Item label={<FormattedMessage id="mySetting.changePassword.section.oldPasswordText" />} hasFeedback>
            {getFieldDecorator('oldPass', {
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: 'Please input your old password!',
                },
              ],
            })(<Input.Password />)}
          </Form.Item>
        )}
        {changePassword && (
          <Form.Item label={<FormattedMessage id="mySetting.changePassword.section.newPasswordText" />} hasFeedback>
            {getFieldDecorator('newPass', {
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: 'Please input your new password!',
                },
                {
                  validator: this.validateToNextPassword,
                },
              ],
            })(<Input.Password />)}
          </Form.Item>
        )}
        {changePassword && (
          <Form.Item label={<FormattedMessage id="mySetting.changePassword.section.confirmPasswordText" />} hasFeedback>
            {getFieldDecorator('confirmPass', {
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ],
            })(<Input.Password onBlur={this.handleConfirmBlur} />)}
          </Form.Item>
        )}
        {changePassword && (
          <Form.Item {...tailFormItemLayout}>
            <Button onClick={() => this.setState({ changePassword: false })}>
              <FormattedMessage id="button.title.cancel" />
            </Button>
            {'  '}
            <Button type="primary" onClick={() => this.updatePassword()}>
              <FormattedMessage id="button.title.updatePassword" />
            </Button>
          </Form.Item>
        )}
      </Form>
    );
  }
}

export default ChangePasswordForm;
