import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'
import { GET_ANALYSES } from './GetAnalyses'
import { GET_ANALYSES_GROUP } from './GetAnalysesGroup'

const UPDATE_ANALYSIS = gql`
  mutation($name: String!, $_id: String!,$blocks:[blockInput!],$_db:String,$groupId:String) {
    updateAnalysis(name: $name, _id: $_id,blocks:$blocks,_db:$_db,groupId:$groupId) {
      name
      blocks{
        type
        content
      }
    }
  }
`

const withUpdateAnalysis = Component => props => (
  <Mutation mutation={UPDATE_ANALYSIS}>
    {updateAnalysis => (
      <Component
        updateAnalysis={({
          name, _id, blocks, _db, groupId,
        }) => updateAnalysis({
          variables: {
            name, _id, blocks, _db, groupId,
          },
          refetchQueries: [
            { query: GET_ANALYSES, variables: { _db: props._db } },
            {
              query: GET_ANALYSES_GROUP, variables: { _db: props._db },
            },
          ],
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
)

export default withUpdateAnalysis
