import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'
import { GET_PRODUCTS } from './GetProducts'
import { GET_STORES } from '../store/GetStores'

const ADD_PRODUCT = gql`
  mutation(
    $name: String!
    $image: String
    $price: Float!
    $currency: String!
    $description: String!
    $productNumber: String!
    $shopId: String
    $taxRate: Float
  ) {
    addProduct(
      name: $name
      image: $image
      price: $price
      currency: $currency
      description: $description
      productNumber: $productNumber
      shopId: $shopId
      taxRate: $taxRate
    ) {
      name,
      image,
      price,
      currency,
      description
      _id
    }
  }
`

const withAddProduct = Component => props => (
  <Mutation mutation={ADD_PRODUCT}>
    {addProduct => (
      <Component
        addProduct={({
          name,
          image,
          price,
          currency,
          description,
          productNumber,
          shopId,
          taxRate,
        }) => addProduct({
          variables: {
            name,
            image,
            price,
            currency,
            description,
            productNumber,
            shopId,
            taxRate,
          },
          refetchQueries: [shopId ? { query: GET_STORES } : { query: GET_PRODUCTS }],
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
)

export default withAddProduct
