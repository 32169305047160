import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'
import { GET_USERS } from './GetUsers'

const ADD_USER = gql`
  mutation(
    $firstName: String
    $lastName: String
    $title: String
    $email: String!
    $password: String!
    $role: String!
    $phone1: String
    $phone2: String
    $partnerShops: [String!]
  ) {
    addUser(
      firstName: $firstName
      lastName: $lastName
      title: $title
      email: $email
      phone1: $phone1
      phone2: $phone2
      password: $password
      role: $role
      partnerShops: $partnerShops
    ) {
      lastName
      firstName
      email
      _id
    }
  }
`

const withAddUser = Component => props => (
  <Mutation mutation={ADD_USER}>
    {addUser => (
      <Component
        addUser={({
          firstName,
          lastName,
          email,
          password,
          role,
          title,
          phone1,
          phone2,
          partnerShops,
        }) => addUser({
          variables: {
            firstName,
            lastName,
            title,
            email,
            password,
            role,
            phone1,
            phone2,
            partnerShops,
          },
          refetchQueries: [{ query: GET_USERS }],
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
)

export default withAddUser
