const initialState = {

}

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case 'SELECTED_ACCOUNT':
      return { ...action.data }
    default:
      return state
  }
}
