import React from 'react'
import { gql } from 'apollo-boost'
import { Query } from 'react-apollo'

export const GET_PRODUCTS = gql`
  query ($productType: String,$shopId: String, $search: String) {
    getAllProducts (productType: $productType, shopId: $shopId, search: $search) {
      _id 
      name 
      image 
      currency 
      price 
      description
      productNumber
      taxRate
      shopId
      deleted 
      }
  }
`

const withProducts = Component => (props) => {
  const { productType, shopId, searchText } = props;
  return (
    <Query
      query={GET_PRODUCTS}
      variables={{ productType, shopId, search: searchText || '' }}
    >
      {({ loading, data, error }) => {
        let errorTosend
        if (error) {
          errorTosend = error.networkError.result
        }
        return (
          <Component
            loading={loading}
            apolloError={errorTosend}
            items={data && data.getAllProducts}
            {...props}
          />
        )
      }}
    </Query>
  )
}

export default withProducts
