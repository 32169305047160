import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'
import { GET_CURRENT_USERS } from './CurrentUser'

const CHANGE_MY_PROFILE_IMAGE = gql`
  mutation(
    $_id:String!
    $imageUrl:String!
  ) {
    changeUserProfilePicture(
      _id: $_id
      imageUrl: $imageUrl
    ) {
      _id
      message
    }
  }
`

const withChangeCrrentUserImage = Component => props => (
  <Mutation mutation={CHANGE_MY_PROFILE_IMAGE}>
    {changeUserProfilePicture => (
      <Component
        changeUserProfilePicture={({ _id, imageUrl }) => changeUserProfilePicture({
          variables: { _id, imageUrl },
          refetchQueries: [{ query: GET_CURRENT_USERS }],
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
)

export default withChangeCrrentUserImage
