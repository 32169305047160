import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'
import { GET_ANALYSES_GROUP } from './GetAnalysesGroup'

const UPDATE_ANALYSIS_GROUP = gql`
  mutation($_id: String!,$name: String!,$_db:String) {
    updateAnalysisGroup(_id: $_id, name: $name,_db:$_db) {
      message
      id
    }
  }
`

const withUpdateAnalysisGroup = Component => props => (
  <Mutation mutation={UPDATE_ANALYSIS_GROUP}>
    {updateAnalysisGroup => (
      <Component
        updateAnalysisGroup={({
          _id, name, _db,
        }) => updateAnalysisGroup({
          variables: {
            _id, name, _db,
          },
          refetchQueries: [{ query: GET_ANALYSES_GROUP, variables: { _db: props._db } }],
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
);

export default withUpdateAnalysisGroup
