import React from 'react'
import {
  Avatar,
} from 'antd';
// import styles from './style.module.scss';


class DPAvatar extends React.Component {
  render() {
    const { src } = this.props;
    if (src) {
      return (
        <span className="src-avatar">
          <Avatar {...this.props} src={undefined}>
            <div className="bg-avatar" style={{ backgroundImage: `url(${src})` }} />
          </Avatar>
        </span>
      )
    }
    return <Avatar {...this.props} />
  }
}

export default DPAvatar;
