import React from 'react'
import { connect } from 'react-redux'
import {
  Menu, Dropdown, Badge, Spin, Icon,
} from 'antd'

import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { withCurrentUser, withLogoutUser } from '../../../../hocs'
import styles from './style.module.scss'
import DPAvatar from '../../../General/DPAvatar'
import MySettingsDialogue from '../../../General/MySettingsDialogue'
// import AuthorizeComponent from '../../AuthorizeComponent'

@withLogoutUser
@withCurrentUser
@withRouter
@connect(({ user }) => ({ user }))
class ProfileMenu extends React.Component {
  state = {
    count: 0,
    mysettingLoader: false,
    editModal: false,
    visible: false,
  }

  logout = () => {
    const { dispatch, logout } = this.props
    logout()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  addCount = () => {
    let { count } = this.state
    const { visible } = this.state
    count = 0
    this.setState({
      count,
      visible: !visible,
    })
  }


  openModal = () => {
    console.log('OPen Modal')
    this.setState({ editModal: true, visible: false })
  }


  handleCancel = () => {
    this.setState({ editModal: false })
  }

  hideMenu = () => {
    console.log('Hide MEnu')
    this.setState({ visible: false })
  }

  redirectToUser() {
    const { history } = this.props
    history.push('/users')
    this.setState({ visible: false })
  }

  redirectToAccounts() {
    const { history } = this.props
    history.push('/accounts')
  }


  render() {
    const {
      currentUser = {}, loading, user: newUser,
    } = this.props
    const { visible } = this.state

    console.log('User infor from Menu=======', this.props)
    console.log('This is state ====>', this.state)

    const { count, mysettingLoader } = this.state
    console.log('Loader form parent ==>', mysettingLoader)
    console.log('Count', count)

    const menu = () => {
      const user = JSON.parse(global.localStorage.getItem('userInfo'))
      console.log('USer =======>', user)
      return (
        <>
          <Menu className={styles.menu} selectable={false}>
            <Menu.Item className={styles.userDetails}>
              <p>
                <span><FormattedMessage id="topBar.profileMenu.role" />:</span>
                {currentUser.role}
              </p>
            </Menu.Item>
            <Menu.Item className={styles.userDetails}>
              <p style={{ marginTop: '-11px' }}>
                <span><FormattedMessage id="topBar.profileMenu.email" />:</span>
                {currentUser.email}
              </p>
            </Menu.Item>
          </Menu>
          <Menu className={styles.menu} selectable={false}>
            <Menu.Item className={styles.userDetails}>
              <MySettingsDialogue data={user} hideMenu={() => this.hideMenu()}>
                <Icon type="user" />
                <span><FormattedMessage id="topBar.profileMenu.mySetting" /></span>
              </MySettingsDialogue>
            </Menu.Item>
            {
              ['admin', 'superadmin'].includes(newUser.role) && (
                <Menu.Item className={styles.userDetails} onClick={() => this.redirectToUser()}>
                  <Icon type="user" />
                  <FormattedMessage id="topBar.Buttons.users" />
                </Menu.Item>)
            }
          </Menu>
          <Menu className={styles.menu} selectable={false}>
            <Menu.Item>
              <a
                role="button"
                tabIndex="0"
                onKeyUp={() => true}
                onClick={this.logout}
              >
                <i className={`${styles.icon} icmn-exit`} />
                <FormattedMessage id="topBar.profileMenu.logout" />
              </a>
            </Menu.Item>
          </Menu>
        </>
      )
    }

    const returnImage = () => {
      if (loading) {
        return <Spin size="default" />
      }
      if (currentUser.profileImage) {
        return <div className={`bg-avatar ${styles.profile}`} style={{ backgroundImage: `url(${currentUser.profileImage})` }} />
      }
      return <DPAvatar className={styles.avatar} shape="square" size="large" icon="user" />
    }

    const badge = (
      <Badge count={count}>
        {
          returnImage()
        }
      </Badge>
    )


    return (
      <div>
        <Dropdown overlay={menu} trigger={['click']} onVisibleChange={this.addCount} visible={visible}>
          <div className={styles.dropdown}>
            {badge}
          </div>
        </Dropdown>
      </div>
    )
  }
}

export default ProfileMenu
