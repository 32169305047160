import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'
import { GET_STORES } from './GetStores'

const ADD_STORE = gql`
  mutation(
    $name: String50!
    $address: String50!
    $city: String!
    $logo: String
    $postalCode: Int!
    $bankAccNum: String
    $dealPercentage: Int
    $partner: Boolean!
  ) {
    addShop(
      name: $name
      address: $address
      city: $city
      logo: $logo
      postalCode: $postalCode
      bankAccNum: $bankAccNum
      dealPercentage: $dealPercentage
      partner: $partner
    ) {
      name
      address
      code
      logo
      _id
    }
  }
`

const withAddStore = Component => props => (
  <Mutation mutation={ADD_STORE}>
    {addShop => (
      <Component
        addShop={({
          name,
          code,
          address,
          city,
          logo,
          postalCode,
          bankAccNum,
          dealPercentage,
          partner,
        }) => addShop({
          variables: {
            name,
            code,
            address,
            city,
            logo,
            postalCode,
            bankAccNum,
            dealPercentage,
            partner,
          },
          refetchQueries: [{ query: GET_STORES }],
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
)

export default withAddStore
