import React from 'react'
import { gql } from 'apollo-boost'
import { Query } from 'react-apollo'

export const GET_STORES = gql`
  query($search: String){
    getAllShops(search: $search){
      _id
      name
      logo
      code
      city
      address
      deleted
      postalCode
      bankAccNum
      partner
      dealPercentage
      frontPage
      categories {
        _id
        name
        image
        description
        deleted
        our_selection
      }
      products {
        _id
        product {
          _id
          name
          image
          price
          currency
          description
          shopId
          productNumber
          taxRate
          deleted
        }
        category
        price
      }
      rcoDoor{
        _id
        id
        name
        accessnumber
        pos
        state
        rasystem
        nexttimecode
        doorstatus {
          isdooropen
          isdoorunlocked
          isdoorforced
          hastimeaccess
          accesstimeleft
          accesstimestart
          errcomunit
          errcomsystem
        }
      }
    }
  }
`

const withStores = Component => (props) => {
  const { searchText } = props;
  return (
    <Query query={GET_STORES} variables={{ search: searchText || '' }}>
      {({ loading, data, error }) => {
        let errorTosend
        if (error) {
          errorTosend = error.networkError.result
        }
        return (
          <Component
            loading={loading}
            apolloError={errorTosend}
            items={data && data.getAllShops}
            {...props}
          />
        )
      }}
    </Query>
  )
}

export default withStores
