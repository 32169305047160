import React from 'react'
import { gql } from 'apollo-boost'
import { Query } from 'react-apollo'

export const GET_ORDERS = gql`
  {
    getAllOrders {
        _id
        name
        is_completed
        shop{
            _id
            name
            code
            address
            city
            logo
            deleted
        }
        created_at
        totalPrice
        products{
            _id
            product{
                _id
                name
                image
                price
                currency
                description
                deleted
            }
            quantity
        }
        orderNumber
    }
  }
`

const withOrders = Component => props => (
  <Query query={GET_ORDERS}>
    {({ loading, data, error }) => {
      let errorTosend
      if (error) {
        errorTosend = error.networkError.result
      }
      return (
        <Component
          loading={loading}
          apolloError={errorTosend}
          orders={data && data.getAllOrders}
          {...props}
        />
      )
    }}
  </Query>
)

export default withOrders
