import React from 'react'
import { Button } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  // MDBNavLink,
  MDBCollapse,
  MDBContainer,
  // MDBHamburgerToggler,
} from 'mdbreact';
import HamburgerMenu from 'react-hamburger-menu'
import LanguageSelector from './LanguageSelector'
import ProfileMenu from './ProfileMenu'
import styles from './style.module.scss'
import logo from '../../../assets/logo/closebuy_black.svg'

@connect(({ user, account }) => ({ user, account }))
class TopBar extends React.Component {
  state = {
    collapse: false,
  }

  toggleSingleCollapse = () => {
    const { collapse } = this.state;
    this.setState({
      collapse: !collapse,
    });
  }

  changeRoute = (routeName) => {
    const { history } = this.props;
    this.setState({ collapse: false }, () => {
      history.push(`/${routeName}`)
    })
  }

  render() {
    const { account, user: { role } } = this.props;
    const { collapse } = this.state;
    const btns = [
      {
        name: <FormattedMessage id="topBar.Buttons.createStore" />,
        route: 'store',
      },
      ...(['admin', 'superadmin'].includes(role.toLowerCase()) ? [{
        name: <FormattedMessage id="topBar.Buttons.product" />,
        route: 'product',
      },
      // {
      //   name: <FormattedMessage id="topBar.Buttons.categories" />,
      //   route: 'categories',
      // },
      // {
      //   name: <FormattedMessage id="topBar.Buttons.orders" />,
      //   route: 'orders',
      // },
      {
        name: <FormattedMessage id="topBar.Buttons.payment" />,
        route: 'payments',
      },
      {
        name: <FormattedMessage id="topBar.Buttons.authentication" />,
        route: 'authentications',
      },
      ] : []),

    ]
    return (
      <div className={styles.topbar}>
        <div className={styles.logo}>
          <div>
            <img src={logo} alt="" />
          </div>
        </div>
        <div className={[`${styles.btns} mr-4 izaan`]}>
          <Button.Group>
            {btns.map((btn, i) => (
              <Button key={i.toString()} onClick={() => this.changeRoute(btn.route)}>
                {btn.name}
              </Button>
            ))}
          </Button.Group>
        </div>
        <div className={styles.hamburger}>
          <MDBContainer>
            <MDBNavbar color="amber lighten-4" light>
              <MDBContainer>
                <MDBNavbarBrand>
                  <div className={styles.logo}>
                    <div>
                      <img src={logo} alt="" />
                    </div>
                  </div>
                </MDBNavbarBrand>
                <div className={styles.toggler}>
                  <HamburgerMenu
                    isOpen={collapse}
                    menuClicked={this.toggleSingleCollapse}
                    width={18}
                    height={15}
                    strokeWidth={2}
                    rotate={0}
                    color="black"
                    borderRadius={0}
                    animationDuration={0.5}
                  />
                </div>
                <MDBCollapse isOpen={collapse} navbar>
                  <MDBNavbarNav left>
                    {btns.map((btn, i) => (
                      <MDBNavItem
                        key={i.toString()}
                        onClick={() => this.changeRoute(btn.route)}
                      >
                        <div className={styles.item}>
                          {btn.name}
                        </div>
                      </MDBNavItem>
                    ))}
                  </MDBNavbarNav>
                </MDBCollapse>
              </MDBContainer>
            </MDBNavbar>
          </MDBContainer>
        </div>
        <div className={styles.right}>
          <div className={styles.combines}>
            <div className="mr-4">
              <LanguageSelector />
            </div>
            {
              account && <ProfileMenu />
            }
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(TopBar)
