
import React, { Component } from 'react';

import {
  Form, Icon, Upload, Button, Input, Spin, Menu, Select,
} from 'antd';
import { FormattedMessage } from 'react-intl'

import {
  withUploadImage,
  withChangeCrrentUserImage,
  withEditCurrentUser,
  withChangeMyPassword,
  withAddUser,
  withEditUser,
  withAddAccountUser,
  withRemoveAccountUser,
  withCurrentUser,
} from '../../../hocs';

import styles from './style.module.scss';
import ChangePasswordForm from './changePasswordForm';
import DPModal from '../DPModal';

const { Option } = Select

@withChangeMyPassword
@withEditCurrentUser
@withChangeCrrentUserImage
@withUploadImage
@withAddUser
@withEditUser
@withAddAccountUser
@withRemoveAccountUser
@withCurrentUser
@Form.create()
class MySettingsDialogue extends Component {
  state = {
    visibleModal: false,
    uploadingImage: false,
    editSettings: true,
    accounts: [],
    // selectedIndex: 0,
    passwordSuccess: false,
    _id: '',
    firstName: '',
    lastName: '',
    title: '',
    phone1: '',
    phone2: '',
    email: '',
    imageUrl: '',
    errMessage: '',
    mysettingLoader: false,
    usersAccounts: [],
    // autoCompVal: '',
    passwordFails: false,
  };

  // componentWillReceiveProps(nextProps) {
  //   const { currentUser } = nextProps
  //   if (currentUser) {
  //     const {
  //       title,
  //       firstName,
  //       lastName,
  //       email,
  //       phone1,
  //       phone2,
  //       role,
  //       profileImage,
  //       _id,
  //     } = currentUser
  //     this.setState({
  //       title,
  //       firstName,
  //       lastName,
  //       email,
  //       phone1,
  //       phone2,
  //       role,
  //       imageUrl: profileImage,
  //       _id,
  //     })
  //   }
  //   this.setState({ accounts: nextProps.accounts || [] })
  // }

  handleCancel = () => {
    this.setState({ visibleModal: false });
  };

  addUserAccount = async (accountId) => {
    const { addAccountUser, _id } = this.props;
    const { usersAccounts } = this.state;
    try {
      await addAccountUser({
        userId: _id,
        accountId,
      })
      const newArray = new Set(usersAccounts)
      newArray.add(accountId)
      // this.setState({ usersAccounts: Array.from(newArray),autoCompVal: '' })
    } catch (err) {
      console.log(err, usersAccounts)
    }
  }


  popFromArray = async (i, accountId) => {
    const { usersAccounts } = this.state;
    const { removeAccountUser, _id } = this.props;
    try {
      const data = await removeAccountUser({
        userId: _id,
        accountId,
      })
      if (data) {
        usersAccounts.splice(i, 1)
        this.setState({ usersAccounts })
      }
    } catch (err) {
      console.log(err, accountId, usersAccounts)
    }
  }

  menu = () => {
    const { accounts } = this.state
    return (
      <Menu>
        {this.state.accounts.map((// eslint-disable-line
          account,
          index,
        ) => (
          <Menu.Item
            onClick={() => {
              this.addUserAccount(accounts[index]._id, index)
            }}
            key={accounts[index]._id}
          >
            {account.name}
          </Menu.Item>
        ))}
      </Menu>
    )
  }

  onSelect = (e) => {
    const { accounts = [] } = this.state;
    // const { onBlur } = this.props;
    const index = accounts.map((ac, i) => (ac.name === e) && i).find(r => typeof r === 'number');
    // onBlur();
    this.addUserAccount(accounts[index]._id, index)
  }

  uploadImage = async (data) => {
    const { uploadFile, changeUserProfilePicture } = this.props;
    const { _id } = this.state;
    this.setState({ uploadingImage: true });
    try {
      const imageResponse = await uploadFile(data.file)
      await changeUserProfilePicture({ _id, imageUrl: imageResponse.data.uploadFile })
      this.setState({ imageUrl: imageResponse.data.uploadFile, uploadingImage: false });
    } catch (err) {
      console.log('This is errorrr =====>', err);
    }
  };

  saveUser = () => {
    const {
      form,
      editCurrentUser,
      _id,
      editUser,
      showRole = true,
      showFirstName = true,
      showLastName = true,
      showTitle = true,
      showPhone1 = true,
      showPhone2 = true,
      showEmail = true,
      showAccounts = true,
      // getEditedUser,
    } = this.props;
    const { key } = this.state
    const { usersAccounts } = this.state;
    form.validateFields((error, values) => {
      if (error) {
        return;
      }
      const userObj = {};
      if (showRole) {
        userObj.role = values.role;
      }
      if (showFirstName) {
        userObj.firstName = values.firstName;
      }
      if (showLastName) {
        userObj.lastName = values.lastName;
      }
      if (showTitle) {
        userObj.title = values.title;
      }
      if (showPhone1) {
        userObj.phone1 = values.phone1;
      }
      if (showPhone2) {
        userObj.phone2 = values.phone2;
      }
      if (showEmail) {
        userObj.email = values.email;
      }
      userObj.key = key
      if (showAccounts) {
        userObj.accounts = usersAccounts;
      }
      this.setState({ mysettingLoader: true }, async () => {
        try {
          // let data;
          if (_id) {
            userObj._id = _id;
            await editUser(userObj);
          } else {
            delete userObj.accounts
            await editCurrentUser(userObj)
            // getEditedUser(userObj)
          }
          this.setState({ mysettingLoader: false, visibleModal: false });
        } catch (err) {
          console.log("There's something wrong in editCurrentUser ===> ", err.message);
          this.setState({ mysettingLoader: false, visibleModal: false });
        }
      });
    });
  };

  onUpdatePassword = async ({ oldPassword, newPassword }) => {
    this.setState({ passwordSuccess: false, errMessage: false });
    const { changeMyPassword } = this.props;
    try {
      await changeMyPassword({ oldPassword, newPassword })
      this.setState({ passwordSuccess: true });
    } catch (err) {
      const errToSent = err.message.split(':')[1]
      this.setState({ errMessage: errToSent, passwordFails: true })
    }
  };

  openDialouge = () => {
    const { hideMenu } = this.props
    if (hideMenu) {
      hideMenu()
    }
    const { data } = this.props;

    const {
      firstName, lastName, title, phone1, phone2, email, profileImage, _id, role, key,
    } = data;
    this.setState({
      _id,
      firstName,
      lastName,
      title,
      phone1,
      phone2,
      email,
      imageUrl: profileImage,
      role,
      key,
      visibleModal: true,
    });
  };

  render() {
    const {
      visibleModal,
      errMessage,
      editSettings,
      imageUrl,
      mysettingLoader,
      uploadingImage,
      passwordSuccess,
      firstName,
      lastName,
      role = '',
      title,
      phone1,
      phone2,
      email,
      loading,
      passwordFails,
      // usersAccounts,
      // accounts,
      // autoCompVal,
      // selectedIndex,
    } = this.state;
    const {
      form,
      children,
      showImage = true,
      showFirstName = true,
      showLastName = true,
      showTitle = true,
      showPhone1 = true,
      showPhone2 = true,
      showEmail = true,
      showChangePassword = true,
      showRole = true,
      // showAccounts = true,
      headTitle = 'My Settings',
      currentUser,
    } = this.props;
    const { getFieldDecorator } = form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    };

    let profileImage;

    if (uploadingImage) {
      profileImage = (
        <Spin
          className={styles.profile}
          style={{ textAlign: 'center', alignItems: 'center' }}
        />
      );
    } else if (imageUrl) {
      profileImage = <div className={`bg-avatar ${styles.image}`} style={{ backgroundImage: `url(${imageUrl})` }} />;
    } else {
      profileImage = (
        <div>
          <Icon type={loading ? 'loading' : 'plus'} />
          <div className="ant-upload-text">Upload</div>
        </div>
      );
    }

    // const check = []
    // const accountList = accounts.map((ac) => {
    //   if (check.indexOf(ac.name) === -1) {
    //     if (usersAccounts.indexOf(ac._id) > -1) {
    //       console.log('already present')
    //     } else {
    //       check.push(ac.name)
    //       return ac.name
    //     }
    //   }
    //   return false
    // }).filter(r => r);

    return (
      <>
        <div
          onClick={this.openDialouge}
          onKeyPress={e => e.keyCode === 13}
          role="button"
          tabIndex="0"
        >
          {children}
        </div>

        <div>
          {true && (
            <DPModal
              visible={visibleModal}
              title={headTitle}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              footer={[
                <Button key="back" onClick={this.handleCancel}>
                  <FormattedMessage id="button.title.close" />
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={this.saveUser}
                  loading={mysettingLoader}
                  disabled={role === 'superadmin' && currentUser.role === 'admin' && true}
                >
                  <FormattedMessage id="button.title.save" />
                </Button>,
              ]}
            >
              {errMessage && (
                <div className={styles.error}>
                  <strong>{errMessage}</strong>
                </div>
              )}
              <Form {...formItemLayout} onSubmit={this.saveUser}>
                {showImage && (
                  <Upload
                    loading
                    accept="image"
                    disabled={!editSettings}
                    name="avatar"
                    listType="picture-card"
                    className={styles.upload}
                    showUploadList={false}
                    customRequest={this.uploadImage}
                    beforeUpload={beforeUpload}
                    onChange={this.handleChange}
                  >
                    {profileImage}
                  </Upload>
                )}
                {showFirstName && (
                  <Form.Item label={<FormattedMessage id="usermanagementview.userList.index.firstName" />}>
                    {getFieldDecorator('firstName', {
                      initialValue: firstName,
                      rules: [],
                    })(
                      <Input
                        onChange={e => form.setFieldsValue({ firstName: e.target.value })}
                        disabled={!editSettings}
                      />,
                    )}
                  </Form.Item>
                )}
                {showLastName && (
                  <Form.Item label={<FormattedMessage id="usermanagementview.userList.index.lasttName" />}>
                    {getFieldDecorator('lastName', {
                      initialValue: lastName,
                      rules: [],
                    })(
                      <Input
                        onChange={e => form.setFieldsValue({ lastName: e.target.value })}
                        disabled={!editSettings}
                      />,
                    )}
                  </Form.Item>
                )}
                {showTitle && (
                  <Form.Item label={<FormattedMessage id="usermanagementview.userList.index.title" />}>
                    {getFieldDecorator('title', {
                      initialValue: title,
                      rules: [],
                    })(
                      <Input
                        onChange={e => form.setFieldsValue({ title: e.target.value })}
                        disabled={!editSettings}
                      />,
                    )}
                  </Form.Item>
                )}
                {showPhone1 && (
                  <Form.Item label={<FormattedMessage id="usermanagementview.userList.index.phone#1" />}>
                    {getFieldDecorator('phone1', {
                      initialValue: phone1,
                      rules: [],
                    })(
                      <Input
                        disabled={!editSettings}
                        type="number"
                        size="default"
                        onChange={e => form.setFieldsValue({ phone1: e.target.value })}
                      />,
                    )}
                  </Form.Item>
                )}
                {showPhone2 && (
                  <Form.Item label={<FormattedMessage id="usermanagementview.userList.index.phone#2" />}>
                    {getFieldDecorator('phone2', {
                      initialValue: phone2,
                      rules: [],
                    })(
                      <Input
                        disabled={!editSettings}
                        type="number"
                        size="default"
                        onChange={e => form.setFieldsValue({ phone2: e.target.value })}
                      />,
                    )}
                  </Form.Item>
                )}
                {showEmail && (
                  <Form.Item label={<FormattedMessage id="usermanagementview.userList.index.email" />}>
                    {getFieldDecorator('email', {
                      initialValue: email,
                      rules: [
                        {
                          required: showEmail,
                          message: 'Please input a valid email address',
                          type: 'email',
                        },
                      ],
                    })(
                      <Input
                        type="email"
                        onChange={e => form.setFieldsValue({ email: e.target.value })}
                      />,
                    )}
                  </Form.Item>
                )}
                {showRole && (
                  <Form.Item label={<FormattedMessage id="usermanagementview.userList.index.role" />}>
                    {form.getFieldDecorator('role', {
                      initialValue: role,
                      rules: [{ required: showRole, message: 'Please select user Role' }],
                    })(
                      <Select
                        placeholder="Select a person"
                        optionFilterProp="children"
                        onChange={value => form.setFieldsValue({ role: value })}
                      >
                        <Option value="partner">Partner</Option>
                        <Option value="admin">Admin</Option>
                        <Option
                          value="superadmin"
                          disabled={currentUser && currentUser.role === 'admin' && true}
                        >
                        Super Admin
                        </Option>
                      </Select>,
                    )}
                  </Form.Item>
                )}
                {/* {showAccounts && (
                  <Form.Item label="All Accounts">
                    <AutoComplete
                      autoFocus
                      dataSource={accountList}
                      style={{ width: 200 }}
                      onSelect={this.onSelect}
                      // onBlur={() => (onBlur ? onBlur() : true)}
                      onChange={e => this.setState({ autoCompVal: e })}
                      filterOption={(inputValue, option) => (
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      )}
                      // onSearch={this.onSearch}
                      value={autoCompVal}
                      placeholder="Select Account"
                    />
                  </Form.Item>
                )}
                {showAccounts && (
                  <Form.Item label="User Assign Accounts">
                    <div className={styles.requipment}>
                      {usersAccounts.map((account, index) => {
                        if (accounts.find(data => data._id === account)) {
                          return (
                            <span style={{ margin: '5px 5px' }} key={account}>
                              <span // eslint-disable-line
                                className={styles.cross}
                                onClick={() => this.popFromArray(index, account)}
                              >
                                {' '}
                                x{' '}
                              </span>
                              {accounts.find(data => data._id === account)
                                ? accounts.find(data => data._id === account).name
                                : ''}
                            </span>
                          )
                        }
                        return null
                      })}
                    </div>
                  </Form.Item>
                )} */}
              </Form>
              <div>
                {passwordSuccess && (
                  <div className={styles.success}>Passowrd Update Successfull</div>
                )}
                {passwordFails && (
                  <div className={styles.fails}>{errMessage}</div>
                )}
              </div>
              {showChangePassword && (
                <ChangePasswordForm onUpdatePassword={this.onUpdatePassword} />
              )}
            </DPModal>
          )}
        </div>
      </>
    );
  }
}

export default MySettingsDialogue;

function beforeUpload() {
  return true;
}
