import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'
// import { GET_CURRENT_USERS } from './CurrentUser';

const UPDATE_DASHBAORD_SETTING = gql`
  mutation($dashboardSetting: String!) {
    updateDashboardSetting(dashboardSetting: $dashboardSetting) {
      id
      message
    }
  }
`

const withUpdateDashboardSetting = Component => props => (
  <Mutation mutation={UPDATE_DASHBAORD_SETTING}>
    {updateDashboardSetting => (
      <Component
        updateDashboardSetting={({ dashboardSetting }) => updateDashboardSetting({
          variables: { dashboardSetting },
          // refetchQueries: [{ query: GET_CURRENT_USERS }],
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
)


export default withUpdateDashboardSetting
