
import {
  all, takeEvery, put, call,
} from 'redux-saga/effects'

import { currentAccount, logout } from '../../services/user'
import actions from './actions'


export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(currentAccount)
  if (response) {
    // console.log("Here Call ==>", response);

    const {
      _id, role, email, token, firstName, lastName, photoURL: avatar, accounts,
    } = response

    yield put({
      type: 'user/SET_STATE',
      payload: {
        _id,
        accounts,
        token,
        firstName,
        lastName,
        name: 'Administrator',
        email,
        avatar,
        role,
        authorized: true,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield call(logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      _id: '',
      lastName: '',
      firstName: '',
      accounts: [],
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
