import React from 'react'
import { gql } from 'apollo-boost'
import { Query } from 'react-apollo'

export const GET_CATEGORIES = gql`
  {
    getAllCategories {
      _id
      name,
      image,
      description,
      our_selection,
      deleted
    }
  }
`

const withCategories = Component => props => (
  <Query query={GET_CATEGORIES}>
    {({ loading, data, error }) => {
      let errorTosend
      if (error) {
        errorTosend = error.networkError.result
      }
      return (
        <Component
          loading={loading}
          apolloError={errorTosend}
          items={data && data.getAllCategories}
          {...props}
        />
      )
    }}
  </Query>
)

export default withCategories
