import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'
import { GET_USERS } from './GetUsers'

const ADD_ACCOUNT_USER = gql`
  mutation(
    $userId:String!
    $accountId:String!
  ) {
    addAccountUser(
      userId: $userId
      accountId: $accountId
    ) {
      message
    }
  }
`

const withAddAccountUser = Component => props => (
  <Mutation mutation={ADD_ACCOUNT_USER}>
    {addAccountUser => (
      <Component
        addAccountUser={({ userId, accountId }) => addAccountUser({
          variables: { userId, accountId },
          refetchQueries: [{ query: GET_USERS }],
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
)

export default withAddAccountUser
