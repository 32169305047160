import React from 'react'
import { gql } from 'apollo-boost'
import { Query } from 'react-apollo'

export const GET_RCO_DOORS = gql`
  query getDoorsForShop($_id: String) {
      getDoorsForShop(_id: $_id) {
        _id
        id
        name
        accessnumber
        pos
        state
        rasystem
        nexttimecode
        doorstatus {
          isdooropen
          isdoorunlocked
          isdoorforced
          hastimeaccess
          accesstimeleft
          accesstimestart
          errcomunit
          errcomsystem
        }
        shop {
          _id
          name
        }
      }
  }
`;

const withRcoDoors = Component => ({ _id, ...props }) => {
  if (!_id) {
    return (
      <Component {...props} />
    )
  }

  return (
    <Query query={GET_RCO_DOORS} variables={{ _id }}>
      {({ loading, data, error }) => {
        let errorToSend
        console.log('withRcoDoors =====>', data?.getDoorsForShop)
        if (error) {
          errorToSend = error.networkError.result
        }
        return (
          <Component
            loading={loading}
            apolloError={errorToSend}
            items={data && data.getDoorsForShop}
            _id={_id}
            {...props}
          />
        )
      }}
    </Query>
  )
}

export default withRcoDoors
