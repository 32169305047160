import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'
import { GET_CATEGORIES } from './GetCategories'

const ADD_CATEGORY = gql`
  mutation(
    $name: String!
    $image: String
    $our_selection: Boolean!
    $description: String!
  ) {
    addCategory(
      name: $name
      image: $image
      our_selection: $our_selection
      description: $description

    ) {
      name,
      image,
      our_selection,
      description
      _id
    }
  }
`

const withAddCategory = Component => props => (
  <Mutation mutation={ADD_CATEGORY}>
    {addCategory => (
      <Component
        addCategory={({
          name,
          image,
          // eslint-disable-next-line camelcase
          our_selection,
          description,
        }) => addCategory({
          variables: {
            name,
            image,
            our_selection,
            description,
          },
          refetchQueries: [{ query: GET_CATEGORIES }],
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
)

export default withAddCategory
