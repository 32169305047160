import React from 'react';
import { gql } from 'apollo-boost';
import { Mutation } from 'react-apollo';
import { GET_STORES } from './GetStores';
import { GET_RCO_DOORS } from './GetRcoDoors';

const DELETE_DOOR_IN_STORE = gql`
  mutation($_id: String!) {
    deleteDoorInShop(_id: $_id) {
      _id
      name
      logo
      code
      city
      address
      deleted
      postalCode
      bankAccNum
      categories {
        _id
        name
        image
        description
        deleted
        our_selection
      }
      products {
        _id
        product {
          _id
          name
          image
          price
          currency
          description
          deleted
        }
        category
        price
      }
      rcoDoor {
        _id
        id
        name
      }
    }
  }
`;

const withDeleteDoorInStore = Component => props => (
  <Mutation mutation={DELETE_DOOR_IN_STORE}>
    {deleteDoorInShop => (
      <Component
        deleteDoorInShop={({ _id }) => (
          deleteDoorInShop({
            variables: {
              _id,
            },
            refetchQueries: [{ query: GET_RCO_DOORS, variables: { _id } }, { query: GET_STORES }],
          })
        )}
        {...props}
      />
    )}
  </Mutation>
);

export default withDeleteDoorInStore;
