import React from 'react';
import { gql } from 'apollo-boost';
import { Mutation } from 'react-apollo';
import { GET_STORES } from './GetStores';
import { GET_RCO_DOORS } from './GetRcoDoors';

const EDIT_DOOR_IN_STORE = gql`
  mutation($_id: String!, $doorId: String!) {
    editDoorInShop(_id: $_id, doorId:$doorId) {
      _id
      name
      logo
      code
      city
      address
      deleted
      postalCode
      bankAccNum
      categories {
        _id
        name
        image
        description
        deleted
        our_selection
      }
      products {
        _id
        product {
          _id
          name
          image
          price
          currency
          description
          deleted
        }
        category
        price
      }
      rcoDoor{
        _id
        id
        name
        accessnumber
        pos
        state
        rasystem
        nexttimecode
        doorstatus {
          isdooropen
          isdoorunlocked
          isdoorforced
          hastimeaccess
          accesstimeleft
          accesstimestart
          errcomunit
          errcomsystem
        }
      }
    }
  }
`;

const withEditDoorInStore = Component => props => (
  <Mutation mutation={EDIT_DOOR_IN_STORE}>
    {editDoorInShop => (
      <Component
        editDoorInShop={({ _id, doorId }) => (
          editDoorInShop({
            variables: {
              _id,
              doorId,
            },
            refetchQueries: [{ query: GET_RCO_DOORS, variables: { _id } }, { query: GET_STORES }],
          })
        )}
        {...props}
      />
    )}
  </Mutation>
);

export default withEditDoorInStore;
