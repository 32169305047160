import React from 'react'
import { gql } from 'apollo-boost'
import { Query } from 'react-apollo'

export const GET_ANALYSES_GROUP = gql`
 query getAnalysesGroup($_db: String) {
    getAnalysesGroup(_db:$_db) {
      _id
      name
      deleted
    }
  }
`

const withAnalysesGroup = Component => (props) => {
  const { _db } = props
  console.log('Database from query', _db);

  return (
    <Query query={GET_ANALYSES_GROUP} variables={{ _db }}>
      {({ loading, data }) => (
        <Component
          loadingGroups={loading}
          groups={data && data.getAnalysesGroup}
          {...props}
        />)
      }
    </Query>
  )
}


export default withAnalysesGroup
