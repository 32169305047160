import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'
import { GET_STORES } from './GetStores'

const DELETE_CATEGORY_IN_STORE = gql`
  mutation(
    $_id: String!
    $categoryId: String!
  ) {
    deleteCategoryInShop(
      _id: $_id
      categoryId: $categoryId
    ) {
        message,
        success
    }
  }
`

const withDeleteCategoryInStore = Component => props => (
  <Mutation mutation={DELETE_CATEGORY_IN_STORE}>
    {deleteCategoryInShop => (
      <Component
        deleteCategoryInShop={({
          _id,
          categoryId,
        }) => deleteCategoryInShop({
          variables: {
            _id,
            categoryId,
          },
          refetchQueries: [{ query: GET_STORES }],
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
)

export default withDeleteCategoryInStore
