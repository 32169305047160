import React from 'react';
import { gql } from 'apollo-boost';
import { Mutation } from 'react-apollo';
import { GET_STORES } from './GetStores';

const EDIT_PRODUCT_IN_STORE = gql`
  mutation($_id: String!, $productId: String!, $product: SubInputProduct!) {
    editProductInShop(_id: $_id, productId:$productId, product: $product) {
      message
    }
  }
`;

const withEditProductInStore = Component => props => (
  <Mutation mutation={EDIT_PRODUCT_IN_STORE}>
    {editProductInShop => (
      <Component
        editProductInShop={({ _id, productId, product }) => {
          editProductInShop({
            variables: {
              _id,
              productId,
              product,
            },
            refetchQueries: [{ query: GET_STORES }],
          });
        }}
        {...props}
      />
    )}
  </Mutation>
);

export default withEditProductInStore;
