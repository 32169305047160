import React from 'react'
import { gql } from 'apollo-boost'
import { Query } from 'react-apollo'

export const GET_USERS = gql`
  {
    getallUsers {
      _id
      title
      firstName
      lastName
      email
      phone1
      phone2
      role
    }
  }
`

const withUsers = Component => props => (
  <Query query={GET_USERS}>
    {({ loading, data, error }) => {
      let errorTosend
      if (error) {
        errorTosend = error.networkError.result
      }
      console.log('UserList here Hocss====>', data)
      return (
        <Component
          loading={loading}
          apolloError={errorTosend}
          items={data && data.getallUsers}
          {...props}
        />
      )
    }}
  </Query>
)

export default withUsers
