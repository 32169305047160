import React from 'react'
import { gql } from 'apollo-boost'
import { Query } from 'react-apollo'

export const GET_SINGLE_ACCOUNT_USERS = gql`
  query getSingleAccountUsers($accountId: String,$_db: String) {
    getSingleAccountUsers(accountId:$accountId,_db:$_db) {
      _id
      title
      firstName
      lastName
      profileImage
      email
      phone1
      phone2
      role
    }
  }
`

const withSingleAccountUsers = Component => (props) => {
  const { accountId, _db } = props
  console.log('Account Id==', accountId)
  return (
    <Query query={GET_SINGLE_ACCOUNT_USERS} variables={accountId ? { accountId } : { _db }}>
      {({ loading, data }) => (
        <Component
          loading={loading}
          users={data ? data.getSingleAccountUsers : []}
          {...props}
        />
      )
      }
    </Query>
  )
}

export default withSingleAccountUsers
