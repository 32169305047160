import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { logger } from 'redux-logger'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
// import { createHashHistory } from 'history'
import { createBrowserHistory as createHistory } from 'history'
import Router from 'router'
import { DraggableModalProvider } from 'ant-design-draggable-modal'
import { ApolloProvider } from 'react-apollo'
import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Localization from './components/Layout/Localization'
import client from './config/createApolloClient'
import * as serviceWorker from './serviceWorker'
// const history: History = createHistory()

// app styles
import './global.scss'
import 'ant-design-draggable-modal/dist/index.css'

const history = createHistory()
// const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [thunk, sagaMiddleware, routeMiddleware]
if (process.env.NODE_ENV === 'development' && true) {
  middlewares.push(logger)
}
const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

ReactDOM.render(
  <DraggableModalProvider>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Localization>
          <Router history={history} />
        </Localization>
      </Provider>
    </ApolloProvider>
  </DraggableModalProvider>,
  global.document.getElementById('root'),
)


serviceWorker.unregister()

export { store, history }
