import React from 'react'
import { gql } from 'apollo-boost'
import { Query } from 'react-apollo'

export const GET_ANALYSES = gql`
 query getAnalyses($_db: String) {
    getAnalyses(_db:$_db) {
      _id
      name
      deleted
      _creatorId{
        lastName
        firstName
        profileImage
        _id
      }
      groupId {
        _id
      }
      blocks{
        _id
        type
        content
        width
        taskId{
          _id
        }
      }
    }
  }
`

const withAnalyses = Component => (props) => {
  const { _db } = props
  console.log('Database from query', _db);

  return (
    <Query query={GET_ANALYSES} variables={{ _db }}>
      {({ loading, data }) => (
        <Component
          loading={loading}
          items={data && data.getAnalyses}
          {...props}
        />)
      }
    </Query>
  )
}


export default withAnalyses
