import React from 'react'
import { gql } from 'apollo-boost'
import { Mutation } from 'react-apollo'
import { GET_CATEGORIES } from './GetCategories'

const EDIT_CATEGORY = gql`
  mutation(
    $_id: String!
    $name: String!
    $image: String
    $our_selection: Boolean!
    $description: String!
  ) {
    editCategory(
      _id: $_id
      name: $name
      image: $image
      our_selection: $our_selection
      description: $description

    ) {
      message,
      success
    }
  }
`

const withEditCategory = Component => props => (
  <Mutation mutation={EDIT_CATEGORY}>
    {editCategory => (
      <Component
        editCategory={({
          name,
          image,
          // eslint-disable-next-line camelcase
          our_selection,
          description,
          _id,
        }) => editCategory({
          variables: {
            name,
            image,
            our_selection,
            description,
            _id,
          },
          refetchQueries: [{ query: GET_CATEGORIES }],
        })
        }
        {...props}
      />
    )
    }
  </Mutation>
)

export default withEditCategory
